@import '../../../styles/helvetica/functions.scss';

.container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 800px;
  max-width: 100%;
  padding: 1em;

  --userpic-size: #{fluid(32, 360, 50, 500)};
}

.header {
  flex: none;
}

.header h3 {
  margin: 0.25em 0 0.75em;
}

.body {
  max-height: 50vh;
  overflow: auto;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.item {
  display: flex;
  align-items: flex-start;
  width: 240px;

  & > *:first-child {
    margin-right: 10px;
  }

  margin-right: 15px;
  margin-bottom: 15px;
}
