.search-form {
  margin-bottom: 15px;
  @media (max-width: 768px) {
    margin-top: 15px;
  }
  @media (min-width: 992px) {
    margin: 28px 0 20px;
  }

  float: right;
  width: 100%;
  display: flex;

  .search-input {
    flex: 1;
    margin-top: 4px;
    margin-right: 5px;
    border: none;
    border-bottom: 1px #dadada solid;

    &:focus {
      outline: none;
    }
  }

  .search-button {
    border: 1px solid #262626;
    background-color: white;
    padding: 2px 6px;
    border-radius: 2px;
  }
}
