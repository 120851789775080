.post-likes {
  padding: rem(4px) 0;
  border-top: 1px solid rgba(117, 117, 117, 0.4);
  border-bottom: 1px solid rgba(117, 117, 117, 0.4);
  display: flex;
  align-items: center;

  .icon {
    // margin-top: rem(2px);
    // float: left;
    color: #ffb914;
  }
}

.more-post-likes-link {
  border-radius: 50%;
  width: 50%;
  height: 50%;
}

.show-more {
  margin: 0 rem(14px);
}

.post-likes-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 rem(15px);
  align-items: center;

  .post-like {
    display: flex;
    margin-left: -10px;
  }

  .throbber {
    position: absolute;

    .throbber-image {
      position: relative;
      top: rem(-1px);
      left: rem(4px);
    }
  }
}
