// Style overrides for Bookmarklet
.bookmarklet {
  h1 {
    font-size: 22px;

    .gamma {
      margin-left: 3px;
    }
  }

  .create-post {
    padding: 0;
    border: none;
  }

  .post-linked-image {
    display: inline-block;
    position: relative;
    cursor: pointer;
    border: 1px solid silver;
    padding: 1px;
    margin: 4px 8px 4px 0;

    img {
      max-width: 200px;
      max-height: 100px;
      width: auto;
      height: auto;
    }

    &:hover::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: silver;
      opacity: 0.7;
    }

    &:hover::after {
      content: '\00d7';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      margin-top: -43px;
      text-align: center;
      color: #333;
      font-size: 70px;
      line-height: 70px;
    }
  }

  .post-linked-image-empty {
    display: inline-block;
    width: 140px;
    height: 104px;
    padding-top: 32px;
    margin: 4px 8px 4px 0;
    border: 1px solid silver;
    background-color: #fff;
    color: #999;
    font-size: 12px;
    font-style: italic;
    text-align: center;
    line-height: 140%;
  }

  .brand-new-post {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 150px;

    a {
      font-size: 17px;
      text-decoration: underline;
    }
  }
}
