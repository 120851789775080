.settings-throbber {
  margin-left: 10px;
}

.checkbox-displayNames-useYou {
  margin-top: 20px;
  margin-bottom: 15px;
}

.has-feedback #displayName-input {
  padding-right: 75px;
}

.displayName-input-hint {
  position: absolute;
  top: 32px;
  right: 12px;
  margin: 0;
}

.archive-source-list {
  margin-left: 20px;

  .source-link {
    color: #333;
    margin: 0 0.3em;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
}
