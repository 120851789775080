// Single side border-radius

@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}
@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
  box-shadow: $shadow;
}

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
  &::before,
  &::after {
    content: ' '; // 1
    display: table; // 2
  }

  &::after {
    clear: both;
  }
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
// Color stops are not available in IE9 and below.
@mixin gradient-vertical(
  $start-color: #555,
  $end-color: #333,
  $start-percent: 0%,
  $end-percent: 100%
) {
  background-image: -webkit-linear-gradient(
    top,
    $start-color $start-percent,
    $end-color $end-percent
  ); // Safari 5.1-6, Chrome 10+

  background-image: -o-linear-gradient(
    top,
    $start-color $start-percent,
    $end-color $end-percent
  ); // Opera 12

  background-image: linear-gradient(
    to bottom,
    $start-color $start-percent,
    $end-color $end-percent
  ); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+

  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

// Converts px to rem units
@function remValue($size) {
  @return $size / 16px;
}

@function rem($size) {
  @return remValue($size) * 1rem;
}
