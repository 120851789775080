// Editing entries

.image-attachments {
  .attachment .remove-attachment {
    display: block;
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
    color: #000;
    font-size: 20px;
    background-color: silver;
    opacity: 0.7;
    border-radius: 0.1em;
    border-bottom-left-radius: 0.25em;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.4em;
    padding: 4px;

    &:hover {
      opacity: 1;
    }
  }

  .attachment.removed {
    border: 2px solid #e33;
    padding: 0;
  }

  .attachment.added {
    border: 2px solid #3d3;
    padding: 0;
  }

  .show-more {
    display: none;
    width: 24px;
    color: #8ab;
    vertical-align: middle;
    margin-bottom: 8px;

    .show-more-icon {
      cursor: pointer;
      transform: rotate(-180deg);
      transition: transform 0.3s 0.1s;
      font-size: 1.75em;
    }
  }

  &.needs-folding {
    .show-more {
      display: inline-block;
    }
  }

  &.is-folded .show-more .show-more-icon {
    transform: rotate(0);
  }

  .lightbox-loading {
    background: rgba(0, 0, 0, 0.8);
    color: #ccc;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.audio-attachments,
.general-attachments {
  .attachment:hover .remove-attachment {
    display: block;
    position: absolute;
    top: 1px;
    width: 1em;
    height: 1em;
    cursor: pointer;
    background-color: #fff;
    border-radius: 2px;

    &:hover {
      background-color: #ddd;
    }
  }

  .attachment.removed {
    background-color: #fbb;
  }

  .attachment.added {
    background-color: #bfb;
  }
}
