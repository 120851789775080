// Dropzone

.dropzone {
  display: none;
}

.dragover .dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  width: 100.16%;
  height: 105px;
  min-height: 0;
  padding: 0;
  border: 5px dotted #ccc;
  background-color: #eee;
  margin-left: -1px;
  font-size: 16px;

  .dz-message {
    margin: 0;
  }
}

.dropzone-previews {
  $image-size: 100px;
  $image-border-radius: 0;

  @mixin prefix($map, $vendors: webkit moz ms o) {
    @each $prop, $value in $map {
      @if $vendors {
        @each $vendor in $vendors {
          #{"-" + $vendor + "-" + $prop}: #{$value};
        }
      }

      // Dump regular property anyway
      #{$prop}: #{$value};
    }
  }

  .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 10px 0;
    min-height: 100px;
    border: 1px solid silver;
    padding: 1px;

    &:hover {
      // Making sure that always the hovered preview element is on top
      z-index: 1000;

      .dz-details {
        opacity: 1;
      }

      .dz-image {
        img {
          @include prefix(
            (
              transform: scale(1.05, 1.05),
            )
          ); // Getting rid of that white bleed-in
          @include prefix(
            (
              filter: blur(8px),
            ),
            webkit
          ); // Getting rid of that white bleed-in
        }
      }
    }

    &.dz-file-preview {
      .dz-image {
        border-radius: $image-border-radius;
        background: #eee;

        // background: linear-gradient(to bottom, #eee, #ddd);
      }

      .dz-details {
        opacity: 1;
      }
    }

    &.dz-image-preview {
      background: white;

      .dz-details {
        @include prefix(
          (
            transition: opacity 0.2s linear,
          )
        );
      }
    }

    .dz-details {
      $background-color: #444;

      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 23px 12px;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);

      $width: 120px;

      line-height: 150%;
      cursor: pointer;

      .dz-size {
        margin-bottom: 1em;
        font-size: 16px;
      }

      .dz-filename {
        white-space: nowrap;

        &:hover {
          span {
            border: 1px solid rgba(200, 200, 200, 0.8);
            background-color: rgba(255, 255, 255, 0.8);
          }
        }

        &:not(:hover) {
          span {
            border: 1px solid transparent;
          }

          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .dz-filename,
      .dz-size {
        span {
          background-color: rgba(255, 255, 255, 0.1);
          padding: 0 4px;
          border-radius: 2px;
        }
      }
    }

    .dz-preview-container {
      position: relative;
    }

    .dz-image {
      border-radius: $image-border-radius;
      overflow: hidden;
      width: $image-size;
      height: $image-size;
      position: relative;
      display: block;
      z-index: 10;

      img {
        display: block;
      }
    }

    &.dz-success {
      .dz-success-mark {
        @include prefix(
          (
            animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1),
          )
        );
      }
    }

    &.dz-error {
      .dz-error-mark {
        opacity: 1;
        @include prefix(
          (
            animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1),
          )
        );
      }

      .dz-error-message {
        display: block;
      }
    }

    .dz-success-mark,
    .dz-error-mark {
      $image-height: 54px;
      $image-width: 54px;

      pointer-events: none;
      opacity: 0;
      z-index: 500;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      margin-left: -($image-width / 2);
      margin-top: -($image-height / 2);

      svg {
        display: block;
        width: $image-width;
        height: $image-height;
      }
    }

    &.dz-processing .dz-progress {
      opacity: 1;
      @include prefix(
        (
          transition: all 0.2s linear,
        )
      );
    }

    &.dz-complete .dz-progress {
      opacity: 0;
      @include prefix(
        (
          transition: opacity 0.4s ease-in,
        )
      );
    }

    &:not(.dz-processing) {
      .dz-progress {
        @include prefix(
          (
            animation: pulse 6s ease infinite,
          )
        );
      }
    }

    .dz-progress {
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 16px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: rgba(255, 255, 255, 0.9);

      // Fix for chrome bug: https://code.google.com/p/chromium/issues/detail?id=157218
      -webkit-transform: scale(1);
      border-radius: 4px;
      overflow: hidden;

      .dz-upload {
        background-color: #666;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        @include prefix(
          (
            transition: width 300ms ease-in-out,
          )
        );
      }
    }

    .dz-error-message {
      $color: rgb(190, 38, 38);

      display: none;
      font-size: 12px;
      width: $image-size;
      background: $color;
      background: linear-gradient(to bottom, $color, darken($color, 5%));
      color: white;
      line-height: 1em;
      padding: 1px;
      margin-top: 1px;
    }
  }
}
