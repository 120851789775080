.comment-likes-container {
  display: flex;
  justify-content: space-evenly;
  margin: 0.5rem 0;

  @media (max-width: 768px) {
    .comment-likes.has-my-like + .comment-time,
    .comment-likes.liked + .comment-time {
      // display: none;
      // color: red;
    }

    .comment-likes.has-my-like {
      color: red;
    }
  }
}

@media (max-width: 768px) {
  .comment-likes-container .comment-likes:not(.has-my-like, .liked) {
    // display: none;
  }
}

.comment-time {
  margin-right: rem(2px);
  flex-shrink: 0;
  flex-basis: auto;
}

.comment-icon {
  opacity: 0.9;

  &,
  &:hover {
    text-decoration: none;
  }

  &.feed-comment-dot {
    height: rem(14px);
    width: rem(14px);
    display: inline-block;
    position: relative;
  }

  &.fa-stack {
    width: rem(14px);
    margin-top: rem(1px);
    margin-right: rem(6px);
    line-height: 1em;
    padding-top: rem(4px);
    color: inherit;
  }
}

.comment-likes {
  // display: inline-flex;
  // align-items: flex-end;
  // flex-shrink: 0;
  // flex-basis: auto;
  // margin-right: rem(2px);
  // margin-top: rem(1px);
  display: flex;
  justify-content: flex-start;
  @media (min-width: 769px) {
    // margin-right: rem(6px);
  }

  &:not(.liked) {
    // opacity: 0;
    transition: opacity 0.25s;
  }

  .comment-heart {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
    font-size: rem(15px);

    .icon.liked {
      animation: 0.3s like;
    }
  }
  @media (min-width: 769px) {
    .comment-heart:hover .icon {
      transform: scale(1.3);
      transition: transform 0.25s;
    }
  }

  .icon {
    // color: red;
    transition: transform 0.25s;
    cursor: pointer;
  }

  &.non-likable {
    @media (min-width: 769px) {
      .comment-heart:hover .icon {
        transform: none;
      }
    }

    .icon {
      cursor: auto;
    }
  }

  .comment-count {
    // min-width: rem(15px);
    // text-align: right;
    cursor: pointer;
    margin-left: rem(10px);

    // margin-right: rem(2px);
    @media (min-width: 769px) {
      // margin-right: rem(6px);
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }

    font-size: rem(12px);
    width: rem(7px);
    color: #898989;
  }

  &.has-my-like {
    .icon,
    .comment-count {
      color: #e66;
    }
  }
}

.comment-likes-list {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  z-index: 2;
  color: #898989;
  border: 1px solid #898989;
  padding: rem(6px) rem(17px) rem(6px) rem(6px);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  text-align: left;
  max-height: rem(200px);
  overflow-y: auto;
  font-size: rem(12px);
  line-height: rem(18px);

  a {
    color: #559;
  }

  .user-name-wrapper {
    display: block;
  }
}

@media (min-width: 769px) {
  .comment-likes-container:hover .comment-likes:not(.liked) {
    opacity: 1;
    transition: opacity 0.25s;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes like {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.actions-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(153, 153, 153, 0.7);
  animation: 0.2s show;

  .container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.actions-panel {
  margin-left: rem(-15px);
  margin-right: rem(-15px);
  background-color: #fff;
  animation: 0.2s 0.2s slide;
  transform: translateY(100%);
  animation-fill-mode: forwards;
  padding-bottom: 0.5em;

  // Support for the iDevices
  @supports (padding: unquote('max(0px)')) {
    padding-bottom: unquote('max(0.5em, env(safe-area-inset-bottom))');
  }

  @media (min-width: 768px) {
    margin-left: rem(63px);
  }

  .likes-panel {
    display: flex;
    background-color: #ddd;

    &.padded {
      padding-bottom: rem(192px);
    }

    .arrow {
      width: rem(35px);
      padding-top: rem(12px);
      line-height: rem(24px);
      text-align: center;
    }

    .likes {
      flex: 1;
      line-height: rem(24px);
      padding-top: rem(12px);
      padding-bottom: rem(12px);
      font-size: rem(16px);
      margin: 0;
      padding-right: rem(15px);
      overflow-y: auto;
    }

    .likes-list-toggle {
      white-space: nowrap;
    }
  }

  .mention-actions {
    width: 100%;
    background-color: white;
    padding-top: rem(12px);
    transition: transform 0.2s;
    transform: translateY(0);
    position: absolute;
    bottom: 0;
  }

  .mention-action {
    height: rem(42px);
    font-size: rem(16px);
    line-height: rem(42px);
    width: 100%;
    padding: 0;
    text-align: left;
    border: none;
    outline: none;
    background-color: white;

    &:active {
      background-color: #ffb914;

      .fa-icon {
        color: #e66;
      }
    }

    .fa-icon {
      position: relative;
      top: rem(-1px);
      font-size: rem(14px);
      width: rem(35px);
      text-align: center;
      opacity: 0.75;
    }
  }
}
