.manage-subscribers-admins {
  padding-top: 10px;
}

.manage-subscribers-body {
  .tile-list {
    padding-bottom: 10px;
  }
}

.group-warning {
  background-color: #fcf8e3;
  padding: 12px 18px;
  border: 1px solid #fbf1d8;
  color: #796e58;
  margin: 10px 0;
}
