.avatar {
  img {
    border: 1px #c1c1c1 solid;
    width: 50px;
    height: 50px;
    box-sizing: content-box;
  }
}

.profile {
  padding-bottom: 14px;
  border-bottom: 1px solid #eee;
  margin-bottom: 14px;
  margin-top: 7px;

  .avatar {
    float: left;

    img {
      width: 75px;
      height: 75px;
    }
  }

  .description {
    font-family: Vazir, Arial, sans-serif;
    color: #000;
    margin-left: 90px;

    .name {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 3px;
    }
  }
}

.profile-stats {
  text-align: right;

  .profile-stats-item {
    white-space: nowrap;
  }

  @media (max-width: 767px) {
    margin-top: 14px;
    border-top: 1px solid #eee;
    padding-top: 4px;
    text-align: left;

    .profile-stats-item {
      display: inline-block;
      margin-right: 15px;
      margin-top: 10px;
    }
  }
}

.profile-controls {
  padding-bottom: 14px;
  border-bottom: 1px solid #eee;
  margin-bottom: 14px;

  .profile-controls-throbber {
    margin: 0 10px;

    &:empty {
      display: none;
    }
  }
}

.profile-actions {
  li {
    display: inline-block;
    white-space: nowrap;

    &::before {
      content: '\2022';
      color: #999;
      margin: 0 0.3em;
    }

    &:first-child::before {
      content: none;
    }
  }
}
