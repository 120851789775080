@import './functions.scss';

// Header

.site-logo {
  @media (max-width: 767px) {
    font-size: rem(30px);
  }
  @media (min-width: 768px) {
    margin-bottom: rem(20px);
  }
  @media (min-width: 992px) {
    margin-left: rem(10px);
  }
}

.site-logo-link {
  color: #222;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: #222;
    text-decoration: none;
  }
}

@mixin beta-color {
  color: #f55 !important;

  &:hover,
  &:focus {
    color: #f55 !important;
  }
}

.site-logo-subheading {
  @include beta-color;

  font-size: 45%;
  text-transform: lowercase;
  margin-left: 0.2em;
  font-weight: normal;
}

.bug-report-link {
  @include beta-color;

  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.75);
  padding: 0.5em;
  padding-bottom: 0.33em;
  padding-left: 0.66em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border-top-left-radius: 0.66em;
  font-size: 130%;

  .dark-theme & {
    background: rgba(255, 255, 255, 0.35);
  }
}

.mobile-shortcuts {
  margin: 0 15px rem(20px) calc(15px - 1em);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: rem(450px);
}

.mobile-shortcut-link {
  margin-left: 1em;
  margin-bottom: 0.5em;
  white-space: nowrap;
}

.signin-link {
  @media (max-width: 767px) {
    margin-top: rem(30px);
  }
  @media (min-width: 768px) {
    margin-top: rem(34px);
  }
}

// This is effectively inline version of <h3>, because we cannot use the latter inside of <p>
.inline-header {
  font-size: rem(24px);
  margin: 0 rem(3px);
  white-space: nowrap;

  &.line-start {
    margin-left: 0;
  }

  @media (max-width: 767px) {
    font-size: rem(20px);
  }
}
