// General

.attachments {
  margin-bottom: 2px;
  display: flex;
  justify-content: center;

  a {
    color: #000088;
    text-decoration: none;

    &:hover span {
      text-decoration: underline;
    }
  }

  .remove-attachment {
    display: none;
  }

  // Clearfix (http://www.cssmojo.com/latest_new_clearfix_so_far/)
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.image-attachments {
  .attachment {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px 8px 0;
    box-sizing: content-box;
    text-align: center;

    &.surplus {
      display: none;
    }
  }

  .image-attachment-img {
    max-width: 525px;
    max-height: 175px;
    background-color: #fff;
    @media (max-width: 560px) {
      max-width: 100%;
      height: auto;
    }
  }

  .image-attachment-link {
    display: block;
    min-width: 32px;
    min-height: 32px;
    line-height: 30px;
    cursor: zoom-in;
    border: 1px solid silver;
    padding: 1px;

    &:hover {
      border-color: #aaa;
    }

    .nsfw-post & {
      position: relative;
      overflow: hidden;

      &::after {
        content: 'NSFW';
        color: #999;
        background-color: #ccc;
        font-weight: bold;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .dark-theme & {
          background-color: #666;
        }
      }
    }
  }

  &.single-image .attachment {
    height: auto;
    line-height: normal;
  }

  &.expanded .attachment.surplus {
    display: inline-block;
  }

  .toggle-surplus {
    display: none;
    color: #fad889; // logotype yellow (#f9b616) with lower saturation;
    font-size: 2em;
    text-decoration: none;
    position: relative;
    top: 2px;
  }

  &.has-surplus .toggle-surplus {
    display: inline-block;

    // fa-chevron-circle-right
    &::before {
      content: '\f138';
    }
  }

  &.has-surplus.expanded .toggle-surplus {
    // fa-chevron-circle-left
    &::before {
      content: '\f137';
    }
  }
}

.pswp__img {
  background-color: #fff;
}

.sortable-images .image-attachment-link {
  cursor: move;
}

.audio-attachments,
.general-attachments {
  .attachment {
    position: relative;
    display: block;
    margin: 0 8px 8px 0;

    .attachment-icon {
      color: #666666;
      padding: 0 1px;
      margin-right: 4px;
    }

    .attachment-title {
      overflow-wrap: break-word;
    }
  }
}
