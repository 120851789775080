ul.tile-list {
  margin: 0;
  padding: 20px 0 0;

  li {
    width: 110px;
    height: 110px;
    display: block;
    float: left;
    list-style-type: none;
    padding-top: 10px;
    text-align: center;

    .avatar {
      margin-bottom: -3px;
    }

    .user-name-wrapper > a,
    .avatar > a {
      line-height: 130%;
      font-size: 12px;
      color: #4c4c4c;
    }

    .user-actions {
      color: #666;
      font-size: 12px;

      a {
        display: inline;
        color: #555599;
      }
    }

    .user-ismutual {
      font-size: 11px;
      font-variant: small-caps;
      color: #999;
    }
  }

  &.with-actions li {
    height: 130px;
  }

  &.large-pics li {
    height: 140px;
  }

  &.with-actions.large-pics li {
    height: 160px;
  }

  @include clearfix;
}

.list-controls {
  .manage-controls {
    text-align: right;
    float: right;
  }

  .filter-controls {
    float: left;
  }
}

.tile-list-subheader {
  margin-bottom: -16px;

  h3 + & {
    margin-top: 24px;
  }
}

.tile-list-text {
  margin: 30px 0;
}
