@import 'dark-vars.scss';

.sidebar {
  a {
    color: #000;
  }

  a.bookmarklet-button {
    color: #000;
    background-color: #ddd;
    border-radius: 4px;
    padding: rem(3px) rem(6px);
    margin: 0;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      color: #fff;
      background-color: #333;
      text-decoration: none;
    }
  }

  .logged-in {
    margin-bottom: rem(23px);

    .avatar {
      float: left;
      margin-right: rem(8px);
    }

    .user .author a {
      font-weight: 600;
    }
  }

  .color-scheme-hint {
    font-size: rem(11px);
    white-space: normal;

    &:empty {
      display: none;
    }

    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }
}

// Mobile sidebar
@media (max-width: 991px) {
  .sidebar {
    --appear-time: 0.2s;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s var(--appear-time), opacity var(--appear-time);

    &--opened {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity var(--appear-time);
    }

    &--no-transitions,
    &--no-transitions .sidebar__content {
      transition: none;
    }
  }

  .sidebar__content {
    position: absolute;
    top: 0;
    right: 0;
    width: 280px;
    height: 100%;
    background-color: #fff;
    padding: rem(20px) rem(15px);
    overflow: hidden;
    overflow-y: auto;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);

    // To prevent accidental click during the transition
    pointer-events: none;
    transform: translateX(100%);
    transition: transform var(--appear-time), pointer-events 0s;

    .dark-theme & {
      background-color: $bg-color;
    }

    .sidebar--opened & {
      transform: translateX(0);
      pointer-events: auto;

      .dark-theme &,
      .dark-theme & .box-body {
        background-color: $bg-color-lighter;
      }
    }
  }

  .logged-in {
    margin-right: rem(40px);
  }
}

.sidebar__close-button {
  position: absolute;
  top: rem(20px);
  right: rem(15px);
  width: rem(40px);
  height: rem(40px);
  font-size: rem(19px);
  border: none;
  background: none;

  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 991px) {
    display: block;
  }
}

.body--no-scroll {
  overflow: hidden;
}

.body--no-select {
  -webkit-user-select: none;
  user-select: none;
}
