.create-post {
  padding-bottom: 14px;
  border-bottom: 1px solid #eee;
  margin-bottom: 14px;

  .uploading-attachments {
    > .ember-view {
      display: inline-block;
      vertical-align: middle;
    }

    .attachment {
      display: inline-block;
      vertical-align: middle;
      margin: 0 8px 8px 0;

      // Progress colors
      $progressCircleColor: #d43e1b;
      $progressCenterColor: #ffffff;
      $progressBackgroundColor: #f9b616;

      // Progress container
      .upload-progress {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: $progressCircleColor; // default 100%
      }

      // Mixin for progress-% classes
      $step: 1; // step of % for created classes
      $loops: round(100 / $step);
      $increment: 360 / $loops;
      $half: round($loops / 2);
      @for $i from 0 through $loops {
        .progress-#{$i * $step} {
          @if $i < $half {
            $nextdeg: 90deg + ($increment * $i);

            background-image: linear-gradient(
                90deg,
                $progressBackgroundColor 50%,
                transparent 50%,
                transparent
              ),
              linear-gradient(
                $nextdeg,
                $progressCircleColor 50%,
                $progressBackgroundColor 50%,
                $progressBackgroundColor
              );
          } @else {
            $nextdeg: -90deg + ($increment * ($i - $half));

            background-image: linear-gradient(
                $nextdeg,
                $progressCircleColor 50%,
                transparent 50%,
                transparent
              ),
              linear-gradient(
                270deg,
                $progressCircleColor 50%,
                $progressBackgroundColor 50%,
                $progressBackgroundColor
              );
          }
        }
      }

      // Centered circle with text
      .upload-progress .center {
        position: absolute;
        width: 54px;
        height: 54px;
        background-color: $progressCenterColor;
        border-radius: 50%;
        margin-left: 23px;
        margin-top: 23px;
        color: #888888;
        text-align: center;
        line-height: 54px;
      }
    }
  }

  .create-post-error {
    font-size: 12px;
    color: #ff5a5f;
  }

  .selector-warning {
    background-color: #fce3e3;
    padding: 12px 18px;
    border: 1px solid #fbd8d8;
    color: #795858;
    margin: 10px 0;
  }
}
