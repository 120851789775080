.invisibleSelect {
  position: relative;
  display: inline-block;

  &:focus-within {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &__select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  &__label {
    border-bottom: 1px dashed;
  }

  &__caret {
    opacity: 0.33;
    font-size: 75%;
    vertical-align: middle;
  }

  &:hover &__caret {
    opacity: 0.5;
  }
}

.invisibleSelect--withCaret {
  .invisibleSelect__label {
    border-bottom: none;
  }
}
