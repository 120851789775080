.box {
  margin-bottom: rem(23px);
  border: 0;
  border-radius: rem(8px);
}

// --- Block headers ---
.box-header {
  line-height: rem(28px);
  min-height: rem(28px);
  border-bottom: 2px black solid;
  color: #000;
}

// User profile, group profile
.box-header-empty {
  @extend .box-header;

  background-color: #fff;
}

// Timeline header
.box-header-timeline {
  @extend .box-header;

  font-size: 170%;
  font-weight: 700;
  margin-bottom: rem(7px);
  margin-left: rem(10px);
  @media (max-width: 991px) {
    margin-left: 0;
  }

  padding-bottom: rem(2px);

  .sidelinks {
    float: right;
    font-size: rem(14px);
    font-weight: normal;
    line-height: rem(28px);

    @media (max-width: 512px) {
      text-transform: capitalize;

      span {
        display: none;
      }
    }
  }

  small {
    font-size: rem(14px);
    font-weight: normal;
    color: #999;
    vertical-align: middle;
  }
}

.box-message {
  margin: 0 0 rem(5px) rem(10px);
  @media (max-width: 991px) {
    margin-left: 0;
  }

  padding: rem(5px) rem(10px);
}

// Sidebar Friend block header
.box-header-friends {
  @extend .box-header;

  font-weight: bold;
  font-size: rem(15px);
}

// Sidebar Group block header
.box-header-groups {
  @extend .box-header;

  font-weight: bold;
  font-size: rem(15px);
}

// Sidebar Group block: additional info in header
.box-header-info {
  line-height: rem(28px);
  padding-top: rem(3px);
  float: right;
  font-size: rem(12px);
}

// Sidebar FreeFeed block header
.box-header-freefeed {
  @extend .box-header;

  font-weight: bold;
  font-size: rem(15px);
}

.box-header-memories {
  @extend .box-header;

  font-weight: bold;
  font-size: rem(15px);
}

// Content and footer
.content {
  .box-body {
    padding: 0 0 0 rem(11px);
    @media (max-width: 991px) {
      padding: 0;
    }

    background-color: #fff;
    @include clearfix;
  }

  .box-footer {
    padding: rem(5px) 0 rem(5px) rem(11px);
    @media (max-width: 991px) {
      padding: rem(15px) 0 0 0;
    }

    font-size: rem(11px);
    background-color: #fff;
  }
}

.sidebar {
  .box-body {
    font-family: Vazir, Arial, sans-serif;
    font-size: rem(15px);
    padding: rem(10px) 0 rem(3px) 0;
    background-color: #fff;

    a {
      color: #0000cc;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
    @include clearfix;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding-bottom: rem(3px);
        white-space: nowrap;
        @include clearfix;

        a {
          color: #000;
          font-size: rem(15px);
        }
      }
    }

    .year-links-row {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      font-size: rem(13px);

      a {
        $dot-width: 1em;

        color: #000;
        white-space: nowrap;
        margin-left: -$dot-width;
        margin-right: $dot-width;

        &::before {
          content: '•';
          display: inline-block;
          width: $dot-width;
          text-align: center;
        }
      }
    }

    .updated-ago {
      display: block;
      font-size: rem(11px);
    }

    .pinned {
      .updated-ago::after {
        content: ' (pinned)';
      }

      + :not(.pinned) {
        border-top: 1px solid #eee;
        margin-top: rem(8px);
        padding-top: rem(7px);
      }
    }
  }

  .box-footer {
    padding: rem(5px) 0 rem(10px) 0;
    font-size: rem(11px);

    a {
      color: #000;
    }
  }
}

.with-label--new::after {
  content: 'NEW';
  background-color: #f9cb16;
  color: white;
  display: inline-block;
  font-size: rem(10px);
  padding: 0 rem(3px);
  vertical-align: rem(1px);
  margin-left: 0.5em;
}

.subscriptions-request-alert {
  padding: 0.3125rem 0.625rem;
  border: 1px solid #bce8f1;
  border-radius: 4px;
  background-color: #d9edf7;
  color: #31708f;
}
