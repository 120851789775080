@font-face {
  unicode-range: U+0600-06ff;
  font-family: Vazir;
  font-style: normal;
  font-weight: 900;
  src: url('~vazir-font/dist/UI/Vazir-Black-UI.eot');
  src: url('~vazir-font/dist/UI/Vazir-Black-UI.woff2') format('woff2'),
    url('~vazir-font/dist/UI/Vazir-Black-UI.woff') format('woff'),
    url('~vazir-font/dist/UI/Vazir-Black-UI.ttf') format('truetype');
}

@font-face {
  unicode-range: U+0600-06ff;
  font-family: Vazir;
  font-style: normal;
  font-weight: bold;
  src: url('~vazir-font/dist/UI/Vazir-Bold-UI.eot');
  src: url('~vazir-font/dist/UI/Vazir-Bold-UI.woff2') format('woff2'),
    url('~vazir-font/dist/UI/Vazir-Bold-UI.woff') format('woff'),
    url('~vazir-font/dist/UI/Vazir-Bold-UI.ttf') format('truetype');
}

@font-face {
  unicode-range: U+0600-06ff;
  font-family: Vazir;
  font-style: normal;
  font-weight: 500;
  src: url('~vazir-font/dist/UI/Vazir-Medium-UI.eot');
  src: url('~vazir-font/dist/UI/Vazir-Medium-UI.woff2') format('woff2'),
    url('~vazir-font/dist/UI/Vazir-Medium-UI.woff') format('woff'),
    url('~vazir-font/dist/UI/Vazir-Medium-UI.ttf') format('truetype');
}

@font-face {
  unicode-range: U+0600-06ff;
  font-family: Vazir;
  font-style: normal;
  font-weight: 300;
  src: url('~vazir-font/dist/UI/Vazir-Light-UI.eot');
  src: url('~vazir-font/dist/UI/Vazir-Light-UI.woff2') format('woff2'),
    url('~vazir-font/dist/UI/Vazir-Light-UI.woff') format('woff'),
    url('~vazir-font/dist/UI/Vazir-Light-UI.ttf') format('truetype');
}

@font-face {
  unicode-range: U+0600-06ff;
  font-family: Vazir;
  font-style: normal;
  font-weight: 200;
  src: url('~vazir-font/dist/UI/Vazir-Thin-UI.eot');
  src: url('~vazir-font/dist/UI/Vazir-Thin-UI.woff2') format('woff2'),
    url('~vazir-font/dist/UI/Vazir-Thin-UI.woff') format('woff'),
    url('~vazir-font/dist/UI/Vazir-Thin-UI.ttf') format('truetype');
}

@font-face {
  unicode-range: U+0600-06ff;
  font-family: Vazir;
  font-style: normal;
  font-weight: normal;
  src: url('~vazir-font/dist/UI/Vazir-Regular-UI.eot');
  src: url('~vazir-font/dist/UI/Vazir-Regular-UI.woff2') format('woff2'),
    url('~vazir-font/dist/UI/Vazir-Regular-UI.woff') format('woff'),
    url('~vazir-font/dist/UI/Vazir-Regular-UI.ttf') format('truetype');
}
