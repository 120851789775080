.picture {
  width: var(--userpic-size, 50px);
  height: var(--userpic-size, 50px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  // border-radius: 2px;
  overflow: hidden;
  font-size: calc(var(--userpic-size, 50px) / 3);
  color: rgba(0, 0, 0, 0.3);
  flex: none;

  &::before {
    font-weight: bold;
    font-size: 1.3em;
    opacity: 0.5;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.pictureLarge {
  width: 75px;
  height: 75px;
  font-size: 27px;
}

.pictureSmall {
  width: 25px;
  height: 25px;
  font-size: 27px;
  border-radius: 50%;
}

.pictureInline {
  width: 1em;
  height: 1em;
  vertical-align: text-bottom;
}

.pictureEmpty {
  background-color: rgba(0, 0, 0, 0.1);

  :global(.dark-theme) & {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.picture::after {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 2px;

  :global(.dark-theme) & {
    border-color: rgba(255, 255, 255, 0.2);
  }
}
