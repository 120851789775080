.languages {
  margin: 0;
  padding: 0;
  text-align: right;
}

.lang {
  display: inline-block;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.active {
  font-weight: bold;
  background-color: #eee;
  text-decoration: none;

  :global(.dark-theme) & {
    background-color: #666;
    color: #000;
  }
}

.main {
  font-size: 16px;
  margin: 20px 10px 40px;
  padding-bottom: 80px;
  border-bottom: 1px solid #eee;
}

.welcome {
  line-height: 30px;
  font-size: 35px;
  font-weight: normal;
  margin: 10px 0 20px;
}

.freefeed {
  font-weight: 500;
  white-space: nowrap;
}

.logo {
  height: 27px;
  width: 27px;
  position: relative;
  top: -3px;
  right: -9px;
  margin-left: -7px;
  font-size: 14px;
}

.p {
  margin: 0 0 50px;
}

.like {
  color: #f9b616;
  font-size: 14px;
  position: relative;
  top: -0.5px;
}

.actions {
  margin: -20px 0 30px;
}

.signup {
  display: inline-block;
  margin-right: 10px;
}

.signin {
  margin-left: 5px;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
}

@media (max-width: 600px) {
  .languages {
    margin-top: -10px;
  }

  .main {
    font-size: 14px;
    margin: 0 0 40px;
  }

  .welcome {
    font-size: 25px;
    margin-bottom: 10px;
  }

  .logo {
    height: 20px;
    width: 20px;
    top: -3px;
    right: -7px;
    margin-left: -7px;
  }

  .p {
    margin-bottom: 20px;
  }

  .actions {
    margin: 0px 0 20px;
  }
}
