.pager {
  text-align: left;
  margin: 15px 0;

  li > a,
  li > a:hover {
    background: none;
    border: 0;
    padding: 5px;
  }

  li > a:hover {
    text-decoration: underline;
  }
}
