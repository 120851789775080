@import '../../styles/helvetica/dark-vars.scss';

.row {
  &::before {
    content: none !important;
  }

  display: flex;
  justify-content: space-between;
}

.chosen {
  background-color: #eee;
  .dark-theme & {
    background-color: $bg-color-lightest;
  }
}

.title {
  white-space: normal;
  flex: 1;
}

.handle {
  --opacity: 0.4;
  :global(.dark-theme) & {
    --opacity: 0.6;
  }

  flex: 0 0 auto;
  cursor: move;
  transition: opacity 0.3s;
  opacity: var(--opacity);

  @media (hover: hover) {
    opacity: 0;

    .row:hover & {
      opacity: var(--opacity);
    }
  }

  &:hover,
  &:focus {
    opacity: 1;
  }
}
