.pswp__zoom-wrap {
  text-align: center;

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.pswp__caption a {
  color: white;
}

.wrapper {
  line-height: 0;
  width: 100%;
  max-width: 900px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

video {
  width: 100% !important;
  height: auto !important;
}

.media-link {
  .icon-bond {
    white-space: nowrap;

    &::after {
      content: '\FEFF';
    }
  }

  .media-icon {
    margin: 0 4px 0 2px;
    display: inline-block;
    vertical-align: middle;
    color: #aaaaaa;

    &.fa-icon-fab-instagram {
      margin-right: 3px;
    }
  }
}
