.user-name-wrapper {
  position: relative;
  overflow-wrap: break-word;
  word-wrap: break-word;

  .user-is-gone {
    color: #666;
  }
}

.display-name {
  display: flex;
  margin: 0 0.1rem 0 0.3rem;
}

.user-card {
  position: absolute;
  z-index: 10000;
  top: 10px;
  left: 0;
  width: rem(280px);
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
  text-align: left;

  .user-card__arrow {
    position: absolute;
    z-index: -1;
    left: 14px;
    top: -5px;
    width: 12px;
    height: 12px;
    background: inherit;
    transform: rotate(45deg);
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
  }

  .user-card-info {
    min-height: rem(97px); // 75px + 2px + 20px
    padding: rem(10px);
    background-color: #fff;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
    font-size: rem(13px) !important;
    line-height: rem(17px);

    .userpic {
      float: left;
      margin: 0 rem(10px) rem(10px) 0;

      &.loading {
        width: 77px;
        height: 77px;
        background-color: #f2f2f2;
      }
    }

    .names {
      min-height: rem(40px);

      .display-name {
        color: #000088;
        font-weight: bold;
        word-break: break-word;
        display: inline-block;
        max-width: rem(170px);
      }

      .username {
        color: #555;
        font-size: rem(13px);
      }
    }

    .description {
      font-size: rem(13px);
      color: #666;
      font-style: italic;
    }

    .relationship-status {
      font-size: rem(13px);
      color: #666;
    }

    .status-icon {
      min-width: rem(13px);
    }

    .feed-status {
      color: #666;

      .icon-protected {
        position: relative;
        display: inline-block;
        transform: scale(0.9);
        min-width: rem(14px);
        height: rem(10px);

        .icon-protected-bg {
          font-size: 0.75em;
          top: 1px;
          position: absolute;
          z-index: 12;
          left: rem(5px);
        }

        .icon-protected-shadow {
          font-size: 0.9em;
          position: absolute;
          left: rem(-1px);
          z-index: 13;
        }

        .icon-protected-fg {
          font-size: 0.9em;
          position: absolute;
          left: rem(-2px);
          z-index: 14;
        }
      }
    }
  }

  .user-card-actions {
    border-top: 1px #e4e4e4 solid;
    padding: rem(5px) rem(10px) rem(6px) rem(10px);
    background-color: #f4f4f4;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    color: #666;
    font-size: rem(13px) !important;
    line-height: rem(17px);

    .user-card-action {
      &::before {
        content: ' - ';
      }

      &:first-child::before {
        content: none;
      }
    }

    a {
      color: #555599 !important;
      font-weight: normal !important;
      white-space: nowrap;
    }

    &.user-card-errors {
      &:empty {
        display: none;
      }

      background-color: #fcc;
    }
  }
}
