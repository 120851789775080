@import '../../styles/shared/mixins';

.icon {
  margin-right: rem(6px);
  font-size: rem(18px);
  opacity: 0.66;
  vertical-align: sub;
}

.active {
  font-weight: bold;
}

.switchIcon {
  font-size: rem(14px);
  font-weight: 400;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
