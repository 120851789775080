@import 'dark-vars.scss';

body {
  background: #fff;
  overflow-anchor: none;
  font-size: rem(14px);
}

body.transition-active::before {
  position: fixed;
  width: 100%;
  height: 100%;
  content: '';
  opacity: 0.8;
  z-index: 1;
  background: #fff;
}

body.transition-active.transition-static::before {
  background-color: transparent;
  opacity: 1;
}

div.jsonly > p {
  padding-left: rem(2px);
}

@media (min-width: 1200px) {
  .container {
    width: 970px !important;
  }
}

a,
a:hover,
a:focus {
  cursor: pointer;
  color: #000088;
}

a[aria-disabled='true'] {
  cursor: default;
  opacity: 0.8;
  text-decoration: none !important;
}

.settings-error {
  margin-left: rem(180px);
  max-width: rem(174px);
}

.header-row {
  margin-bottom: rem(10px);
}

.footer {
  font-size: rem(14px);
  margin-bottom: rem(20px);
  margin-left: rem(10px);
  @media (max-width: 991px) {
    margin-left: 0;
  }
}

.error-string {
  color: #ff5a5f;
}

.throbber-image {
  vertical-align: middle;
}

.fa-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

.iconic-button {
  outline: none !important;
  display: inline-flex;
  border-radius: 50%;
  position: relative;
  top: -0.3em;
  width: 1.6em;
  height: 1.6em;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;

  &:hover,
  &:active,
  &:focus {
    background: #ddd;

    .dark-theme & {
      background: $bg-color-lightest;
    }
  }
}

// Override Bootstrap absolute sizes

html {
  font-size: initial;
}

p {
  margin-bottom: rem(10px);
}

h3,
.h3 {
  font-size: rem(24px);
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: rem(20px);
  margin-bottom: rem(10px);
}

h4,
.h4 {
  font-size: rem(18px);
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: rem(10px);
  margin-bottom: rem(10px);
}

label {
  margin-bottom: rem(5px);
}

.btn {
  padding: rem(6px) rem(12px);
  font-size: rem(14px);
}

.btn-xs,
.btn-group-xs > .btn {
  padding: rem(1px) rem(5px);
  font-size: rem(12px);
}

.btn-sm,
.btn-group-sm > .btn {
  padding: rem(5px) rem(10px);
  font-size: rem(12px);
}

.form-control {
  height: rem(34px);
  padding: rem(6px) rem(12px);
  font-size: rem(14px);
}

.form-group {
  margin-bottom: rem(15px);
}

.radio,
.checkbox {
  margin-top: rem(10px);
  margin-bottom: rem(10px);
}

.radio label,
.checkbox label {
  min-height: rem(20px);
  padding-left: rem(20px);
}

input[type='radio'],
input[type='checkbox'] {
  margin: rem(4px) 0 0;
  width: rem(13px);
  height: rem(13px);
}

.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
  margin-left: rem(-20px);
}

.help-block {
  margin-top: rem(5px);
  margin-bottom: rem(10px);
}

ul,
ol {
  margin-bottom: rem(10px);
}

/**
 * Prevent the mobile Chrome unwanted font boosting
 * See https://stackoverflow.com/a/12965146
 */
.content .box-body,
.sidebar .box {
  max-height: 999999px;
}
