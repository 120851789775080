.signup-by-invite {
  .personal-message {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 10px;
  }

  .signup-form-container {
    @media (min-width: 992px) {
      width: 50%;
    }
  }
}
