@import 'dark-vars.scss';

body,
.sidebar .box-body,
.search-form .search-button,
.search-form .search-input,
.content .box-body,
.content .box-footer,
.expandable .expand-panel {
  transition: background 0.2s;
}

.dark-theme {
  body,
  .sidebar .box-body,
  .search-form .search-button,
  .search-form .search-input,
  .content .box-body,
  .content .box-footer,
  .list-group-item,
  .panel {
    background-color: $bg-color;
  }

  .arrow-span,
  .sidebar a.bookmarklet-button,
  select[name='os0'],
  .color-scheme-selector select,
  .search-memo,
  .feed-options-switch .dropdown,
  .loader-container .loader-overlay,
  .breadcrumb {
    background-color: $bg-color-lighter;
  }

  .arrow-span {
    background-color: $bg-color-lightest;
  }

  code {
    color: $text-color-lighter;
    background-color: $bg-color-lightest;
  }

  .timeline-post.direct-post {
    border-bottom-color: $bg-color-lighter;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' viewBox='0 0 48 48'><path fill='%23505050' d='M40 8H8c-2.21 0-3.98 1.79-3.98 4L4 36c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V12c0-2.21-1.79-4-4-4zm0 8L24 26 8 16v-4l16 10 16-10v4z'/></svg>");
  }

  .image-attachments .image-attachment-link,
  .avatar img,
  select[name='os0'],
  .feed-options-switch .dropdown,
  .list-group-item,
  .panel-default,
  .hidden-posts-toggle,
  hr {
    border-color: $separator-color;
  }

  body,
  .post .post-body .post-text,
  .post .post-body,
  .box-header,
  .box-header-empty,
  .box-header-timeline,
  .box-header-friends,
  .box-header-groups,
  .box-header-freefeed,
  .box-header-memories,
  .site-logo-link,
  .site-logo-link:active,
  .site-logo-link:focus,
  .site-logo-link:hover,
  .site-logo-link:visited,
  .sidebar .box-body ul li a,
  .sidebar .box-footer a,
  .sidebar a,
  .sidebar .box-body .year-links-row a,
  .profile .description,
  ul.tile-list li .user-name-wrapper > a,
  ul.tile-list li .avatar > a {
    color: $text-color;
  }

  .sidebar,
  .post-editor .post-edit-options .post-edit-more-trigger,
  .comment-likes .comment-count,
  .post .post-body .post-footer .post-timestamp,
  .post-footer,
  .post-timestamp,
  .text-muted,
  .post .comment-timestamp a,
  .breadcrumb > .active {
    color: $text-color-darker;
  }

  .profile,
  .profile-controls,
  .post,
  .box-header,
  .box-header-empty,
  .box-header-timeline,
  .box-header-friends,
  .box-header-groups,
  .box-header-freefeed,
  .box-header-memories,
  .search-form .search-input,
  .create-post {
    border-bottom-color: $separator-color;
  }

  .feed-comment-dot::after {
    background-color: $text-color-darker;
  }

  .post-timestamp a {
    color: $text-color-lighter;
  }

  a,
  a:hover,
  a:focus,
  // .post .post-header a,
  .post .post-body .post-text a {
    color: $link-color;
  }

  .pager li > a:hover,
  .pager li > a:focus {
    color: $link-color;
    background-color: $bg-color-lightest;
  }

  .post .read-more {
    color: $link-color !important;
  }

  .post a,
  .expandable .expand-panel .expand-button,
  .image-attachments .show-more,
  a.p-sendto-toggler {
    color: $link-color-dim;
  }

  .link-preview .folder-container .content-wrapper.folded::after {
    background: linear-gradient(
      scale-color($bg-color, $alpha: -100%) 0%,
      scale-color($bg-color, $alpha: -80%) 25%,
      scale-color($bg-color, $alpha: -20%) 75%,
      $bg-color
    );
  }

  .timeline-post.direct-post .link-preview .folder-container .content-wrapper.folded::after {
    background: linear-gradient(
      scale-color($bg-color-lighter, $alpha: -100%) 0%,
      scale-color($bg-color-lighter, $alpha: -80%) 25%,
      scale-color($bg-color-lighter, $alpha: -20%) 75%,
      $bg-color-lighter
    );
  }

  .post-editor .post-textarea,
  .comment .comment-textarea,
  .form-control {
    background: $bg-color-lighter;
    border: 1px solid $separator-color;
    color: $text-color;
  }

  .comment .comment-textarea__dragged {
    background: $bg-color-lightest;
  }

  .btn-default {
    color: $text-color;
    background: $bg-color-lightest;
    border-color: $separator-color;

    &:disabled {
      color: $text-color-darker;
    }
  }

  .btn-default:hover,
  .btn-default:focus,
  .btn-default.focus,
  .btn-default:active,
  .btn-default.active,
  .open > .btn-default.dropdown-toggle {
    color: $text-color-lighter;
    background: $bg-color-lightest;
    border-color: $text-color-lighter;
  }

  .btn-primary,
  .btn-primary:hover {
    color: #fff;
  }

  .alert-info {
    color: $text-color-lighter;
    background: $bg-highlight-color;
    border-color: $bg-highlight-color;
  }

  .search-highlight,
  .feed-options-switch .dropdown .drop-option:hover {
    color: $text-color-lighter;
    background-color: $bg-highlight-color;
  }

  .feed-options-switch .dropdown .spacer {
    background-color: $separator-color;
  }

  .feed-options-switch .dropdown {
    color: $text-color-lighter;
  }

  .user-card {
    background-color: $bg-color-lighter;
    color: $text-color;

    .user-card-info {
      background-color: inherit;
      color: inherit;

      .names {
        .display-name {
          color: $link-color;
        }

        .username {
          color: $text-color;
        }
      }

      .description {
        color: $text-color;
      }

      .relationship-status {
        color: $text-color;
      }

      .feed-status {
        color: $text-color;
      }
    }

    .user-card-actions {
      border-top-color: $bg-color-lightest;
      background-color: $bg-color-lightest;
      color: $text-color;

      a {
        color: $link-color !important;
      }

      &.user-card-errors {
        color: $text-color-lighter;
        background-color: $bg-highlight-color;
      }
    }
  }

  // Select-control

  .Select-control {
    background-color: $bg-color-lighter;
    border-color: $separator-color;

    .is-open > & {
      background-color: $bg-color-lighter;
      border-color: $text-color-darker;
    }
  }

  .Select-placeholder,
  :not(.Select--multi) > .Select-control .Select-value {
    color: $text-color-darker;
  }

  .Select--multi .Select-value,
  .Select-value-label-standalone {
    color: $text-color;
    background-color: $bg-color-lightest;
    border-color: $text-color-darker;
  }

  .Select--multi .Select-value-icon {
    border-color: $text-color-darker;
  }

  .Select--multi .Select-value-icon:hover,
  .Select--multi .Select-value-icon:focus {
    background-color: $bg-color-lightest;
    color: $text-color-lighter;
  }

  .Select-menu-outer {
    background-color: $bg-color-lighter;
    border-color: $separator-color;
  }

  .Select-input > input,
  .Select-option {
    color: $text-color;
  }

  .Select-option.is-focused {
    background-color: $bg-highlight-color;
    color: $text-color-lighter;
  }

  // end of Select-control

  .post-likes .icon,
  .comment-likes .icon {
    opacity: 0.9;
  }

  .link-preview .video-preview .static-preview .play-icon {
    color: $text-color;
  }

  .nsfw-bar {
    color: $text-color-darker;
  }

  .comment-likes-list {
    background-color: $bg-color-lighter;
    border-color: $separator-color;
  }

  .image-attachments .attachment .remove-attachment {
    background-color: $bg-color-lightest;
    color: $text-color;
  }

  .panel-default > .panel-heading {
    background-color: $bg-color-lighter;
    border-color: $separator-color;
    color: $text-color;
  }

  .actions-overlay {
    background-color: scale-color($bg-color, $alpha: -40%);
  }

  .actions-panel {
    background-color: $bg-color-lighter;
    color: $text-color-lighter;

    .mention-actions,
    .mention-action {
      background-color: $bg-color-lighter;
    }

    .likes-panel {
      background-color: $bg-color-lightest;
    }
  }

  .audio-attachments audio {
    filter: invert(1) contrast(70%) hue-rotate(180deg);
  }

  .alert-success {
    background-color: mix($bg-color, #dff0d8, 75%);
    border-color: mix($separator-color, #d6e9c6, 75%);
    color: mix($text-color, #3c763d, 75%);
  }

  .with-label--new::after {
    background-color: $label-bg-color;
    color: $label-text-color;
  }

  .recently-hidden-post .recently-hidden-post__close:hover {
    background-color: $bg-color-lighter;
  }

  .pagination > li > a,
  .pagination > li > span {
    color: $text-color;
    background-color: $bg-color;
    border-color: $text-color-darker;
  }

  .pagination > .active > a,
  .pagination > .active > a:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span,
  .pagination > .active > span:hover,
  .pagination > .active > span:focus {
    color: $text-color-lighter;
    background-color: $bg-highlight-color;
    border-color: $bg-highlight-color;
  }

  .nav > li > a:hover,
  .nav > li > a:focus {
    background-color: $bg-color-lighter;
  }

  .nav-tabs > li > a:hover {
    border-color: $bg-color-lighter $bg-color-lighter $separator-color;
  }

  .nav-tabs {
    border-bottom-color: $separator-color;
  }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    color: $text-color;
    background-color: $bg-color;
    border-color: $separator-color;
    border-bottom-color: transparent;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    // Do not override 'select' color on mobile iOS devices
    // See https://stackoverflow.com/a/47818418
    select {
      color: initial !important;
    }
  }
}
