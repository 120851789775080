// @import "../common/select2/4.0.0-rc.2/theme/classic/_defaults";
// @import "../common/select2/4.0.0-rc.2/theme/classic/_multiple.scss";

.send-to {
  padding-bottom: 6px;
}

.select2-container--default {
  @extend .select2-container--open;

  &.select2-container--disabled {
    .select2-selection--multiple {
      border: 0 !important;
      background-color: #fff;
    }
  }

  .select2-selection--multiple {
    border: none;

    .select2-selection__choice {
      background-color: #f2f2f2;
      border: 1px solid #d8d8d8;
    }
  }
}

.select2-search__field {
  width: -moz-calc(100% - 25px) !important;
  width: -webkit-calc(100% - 25px) !important;
  width: calc(100% - 25px) !important;
}

.select2-container--open {
  .select2-selection--multiple {
    border: 1px solid #aaa !important;
  }
}

.select2-container--focus {
  .select2-selection--multiple {
    border: 1px solid #ccc !important;
  }
}

.send-to a {
  position: relative;
  vertical-align: middle;
  line-height: 33px;
}

// textarea for add|edit posts and comments
@mixin editarea {
  border: 1px solid #b4b4b4;
  border-top-color: #727272;
  border-bottom-color: #dadada;
  background: white;
  background-image: linear-gradient(to bottom, #b4b4b4 0, #fff 2px);
  padding: 3px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 3px;
}

label.option-box {
  display: block;

  .input {
    display: table-cell;
  }

  .option {
    display: table-cell;
    font-weight: normal;
    padding-left: 10px;
    padding-bottom: 5px;
  }
}

.checkbox-disabled label {
  color: #aaa;
}

.narrow-input {
  max-width: 20em;
  resize: vertical;
}

.wider-input {
  max-width: 40em;
  resize: vertical;
}

.signup-userpic {
  max-width: 80px;
  max-height: 80px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 2px;
  transition: opacity 0.25s;
}

.signup-userpic.disabled {
  opacity: 0.5;
}
